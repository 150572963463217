
<script>
	export default {
		name: "SocialLink",
	}
</script>

<script setup>
	defineProps([
		'image',
		'name',
		'link'
	])
</script>

<template>
	<a :href="link">
		<img :src="image" />

		<p>{{ name }}</p>
	</a>
</template>

<style scoped>
	a {
		width: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}

	a:hover {
		transition: 0.2s;
		transform: scale(1.05);
	}

	a:active {
		transform: scale(0.95);
	}

	img {
		width: 50px;
		height: 50px;

		max-width:70%;
		object-fit: cover;
	}

	p {
		font-size: 12px;
	}
</style>