<script>
	export default {
		name: 'HelloWorld'
	}
</script>

<script setup>
	import SocialLink from "@/components/SocialLink";
	// import ImageTitleAndDescription from "@/components/ImageTitleAndDescription";
	import MenuItem from "@/components/MenuItem";
	// import SunMoonSwitch from "@/components/SunMoonSwitch";
	import PriceCard from "@/components/PriceCard";
</script>

<template>
	<!--Header-->
	<div class="header">
		<div class="header-row viewLimited">
<!--			<SunMoonSwitch/>-->

<!--			<p class="header-logo">Магия таро</p>-->

			<img
				class="header-logo"
				src="images/septogram.png"
			/>

			<input class="header-menu-button" type="checkbox" id="menu-btn">
			<label class="header-menu-icon" for="menu-btn"><span class="header-menu-icon-span"></span></label>

			<ul class="header-menu">
				<MenuItem
					icon="info"
					name="Обо мне"
					link="#about"
				/>

				<MenuItem
					icon="shopping_cart"
					name="Услуги"
					link="#pricing"
				/>

				<MenuItem
					icon="sms"
					name="Отзывы"
					link="#reviews"
				/>

				<MenuItem
					icon="phone"
					name="Контакты"
					link="#contacts"
				/>
			</ul>
		</div>
	</div>

	<!--	Title & image-->
	<div
		style="
			background-image: linear-gradient(
				to bottom,
				rgba(0,0,0,0.5),
				rgba(0,0,0,0.9)
			), url('images/cards3.jpeg');
			background-repeat: no-repeat;
			background-size: cover;
			height: 100vh;
			padding-top: var(--header-height);
			margin-bottom: calc(var(--header-height) * -1);
			display: flex;
			align-items: center;
		"
	>
		<div
			class="viewLimited rows"
		>
			<h1
				style="
					color: var(--theme-accent-bg1);
				"
			>Юлия Тимофеева</h1>

			<p
				style="
					color: var(--theme-fg3);
					max-width: 700px;
				"
			>
				Профессиональный таролог. Помогу ответить на любой жизненный вопрос и принять верное решение, разложив карты Таро. Знайте, что Вы не одни, и выход есть всегда!
			</p>

			<a
				style="
					align-self: start;
				"
				class="roundButton"
				href="https://vk.com/im?sel=7613327"
			>Связаться</a>
		</div>
	</div>

	<div id="about" class="content">
		<div class="rows viewLimited">
			<div class="aboutRow">
				<div class="rows">
					<h1>Обо мне</h1>

					<p>
						Профессиональный психолог и сертифицированный таролог. Более 300 человек в разное время приходили ко мне на консультацию. Многие из них обращаются к советам Таро постоянно на протяжении нескольких лет.
					</p>

					<br/>

					<p>
						Прогнозы на картах Таро - самые точные, и делаю я их хорошо.
						Вопросы могут быть любые:
						про отношения, про деньги, про здоровье или по конкретной ситуации: о работе, о поездках, о купле/продаже, судебных делах, об учебе — перечислить все невозможно.
					</p>

					<br/>

					<p>
						Также могу провести диагностику магических воздействий - порча, сглаз, привороты т.д.
						И, конечно, провести ритуалы на здоровье, материальное благополучие и защиту!
					</p>
				</div>

				<img src="images/maman2.png" class="aboutImage"/>
			</div>
		</div>
	</div>

	<div id="reviews" class="contentAlt">
		<div class="rows viewLimited">
			<h1>Отзывы клиентов</h1>

			<p>Ваши отзывы согревают мне сердце, радуют душу и служат гарантом честности для ищущих помощи. Благодарю!</p>

			<div class="reviewImages">
				<img src="images/review1.jpg"/>
				<img src="images/review2.jpg"/>
				<img src="images/review3.jpg"/>
				<img src="images/review4.jpg"/>
			</div>
		</div>
	</div>

	<div id="pricing" class="content">
		<div class="rows viewLimited">
			<h1>Услуги</h1>

			<p>
				Раскладов на картах Таро множество, практик и ритуалов - тоже.
				И расклад, и практика подбираются под конкретный запрос и нужды клиента.
				Ниже приведен список наиболее популярных и востребованных. Цены лояльные.
			</p>

			<div class="priceCards">
				<PriceCard
					title="Часовая консультация"
					description="Вы получите ответы на все интересующие вас вопросы, в любой сфере жизни (здоровье, финансы, личная жизнь, работа)"
					price="1 500"
				/>

				<PriceCard
					title="Практика &quot;Денежная свеча&quot;"
					description="Активирует Ваш денежный поток, привлекая финансы из разных источников и новые возможности заработка."
					price="600"
				/>

				<PriceCard
					title="Ритуал &quot;Гармония&quot;"
					description="Нормализует Ваше здоровье и душевное состояние. Негатив отливается воском, позитив активируется огнём свечей."
					price="3 000"
				/>

				<PriceCard
					title="Ритуал &quot;Двойная пентаграмма&quot;"
					description="Защита для Вас и Ваших близких от любого негатива, в том числе и от магических воздействий."
					price="1 000"
				/>

				<PriceCard
					title="Ритуал &quot;Возвращаю назад&quot;"
					description="Защита для Вас от конкретного недоброжелателя. Осуществляется после предварительной консультации."
					price="3 000"
				/>

				<PriceCard
					title="Расклад &quot;Исполнение желания&quot;"
					description="Помогает выяснить истинность Ваших желаний, выбрать верный путь для оптимального достижения цели."
					price="1 000"
				/>

				<PriceCard
					title="Ритуал &quot;Благополучный год&quot;"
					description="Практика с очень сильной энергией, улучшающая все сферы вашей жизни в течение года."
					price="3 000"
				/>

				<PriceCard
					title="Ритуал &quot;Мостик любви&quot;"
					description="Улучшает Ваши отношения с партнером (любовные или деловые), возвращает романтические и дружеские чувства."
					price="3 000"
				/>

				<PriceCard
					title="Практика &quot;Ангельская поддержка&quot;"
					description="Человек выходит на высокий уровень сознания, доверия к миру, прощения и сострадания, смысла жизни и миссии. Уровень Божественной поддержки, когда тебя и подхватят, и вытащат, и спасут."
					price="1 000"
				/>

				<PriceCard
					title="Личное магическое сопровождение"
					description="10 ритуалов;
7 недель личного общения для настройки поля;
охранный талисман, «работающий» постоянно, днем и ночью, на Вашу защиту и привлечение новых возможностей."
					price="21 000"
				/>
			</div>
		</div>
	</div>

	<div id="contacts" class="contentAlt">
		<div class="rows viewLimited">
			<div class="rows">
				<h1>Контакты</h1>

				<p>
					Приглашаю на консультацию!
					Записаться можно по телефону <b>+7 (921) 979-69-35</b> или в личных сообщениях во Вконтакте, WhatsApp, или Telegram.
				</p>

				<div class="columns">
					<SocialLink
						image="images/vk.svg"
						name="ВКонтакте"
						link="https://vk.com/id7613327"
					/>

					<SocialLink
						image="images/whatsapp.svg"
						name="WhatsApp"
						link="https://api.whatsapp.com/send/?phone=79219796935&text&type=phone_number&app_absent=0"
					/>

<!--					<SocialLink-->
<!--						image="images/mailru.svg"-->
<!--						name="Эл. почта"-->
<!--						link="mailto:29-06@mail.ru"-->
<!--					/>-->

					<SocialLink
						image="images/youtube.svg"
						name="YouTube"
						link="https://www.youtube.com/@user-tv2sm5hy6i/videos"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.header {
		width: 100%;
		min-height: var(--header-height);
		max-height: var(--header-height);
		position: fixed;
		z-index: 2;

		display: flex;
		justify-content: space-between;

		background: var(--theme-bg3);
		background-repeat: repeat;
		background-size: 500px;

		box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.3);
	}

	.header-row {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		column-gap: 10px;
	}

	.header-logo {
		max-height: 40px;
		min-height: 40px;

		object-position: top left;

		font-family: Ubuntu, "Open Sans",serif;
		font-size: 20px;
		color: var(--theme-accent-bg1);
	}

	/*--------------------------------------------------------------------------*/

	/* menu */
	.header-menu {
		clear: none;
		max-height: none;

		transition: max-height .2s ease-out;

		margin: 0 0 0 0;
		padding: 0 0 0 0;
		list-style: none;
		overflow: hidden;

		flex-grow: 1;
		display: flex;
		flex-direction: row;
		column-gap: 5px;
		justify-content: left;
		align-items: stretch;
	}

	/* menu icon */
	.header-menu-icon {
		cursor: pointer;
		user-select: none;
		display: none;

		padding: 10px 0 10px 10px;
	}

	.header-menu-icon-span {
		background: var(--theme-fg6);
		display: block;
		height: 2px;
		position: relative;
		transition: background .2s ease-out;
		width: 18px;
	}

	.header-menu-icon-span:before,
	.header-menu-icon-span:after {
		background: var(--theme-fg6);
		content: '';
		display: block;
		height: 100%;
		position: absolute;
		transition: all .2s ease-out;
		width: 100%;
	}

	.header-menu-icon-span:before {
		top: 5px;
	}

	.header-menu-icon-span:after {
		top: -5px;
	}

	/* menu btn */
	.header-menu-button {
		display: none;
	}

	.header-menu-button:checked ~ .header-menu {
		max-height: 240px;
	}

	.header-menu-button:checked ~ .header-menu-icon .header-menu-icon-span {
		background: transparent;
	}

	.header-menu-button:checked ~ .header-menu-icon .header-menu-icon-span:before {
		transform: rotate(-45deg);
	}

	.header-menu-button:checked ~ .header-menu-icon .header-menu-icon-span:after {
		transform: rotate(45deg);
	}

	.header-menu-button:checked ~ .header-menu-icon:not(.steps) .header-menu-icon-span:before,
	.header-menu-button:checked ~ .header-menu-icon:not(.steps) .header-menu-icon-span:after {
		top: 0;
	}

	.aboutRow {
		display: flex;
		flex-direction: row;
		column-gap: 40px;
		row-gap: 40px;
	}

	.aboutImage {
		min-width: 250px;
		max-width: 250px;
		border-radius: 10px;
		object-fit: cover;
		object-position: 10px 10px;
		image-rendering: auto;
	}

	.reviewImages {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		row-gap: 20px;
		column-gap: 20px;
	}

	.reviewImages > * {
		min-width: 100%;
		min-height: 100%;
		max-width: 100%;
		max-height: 100%;
		border-radius: 10px;
		object-fit: cover;
	}

	.priceCards {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 25px;
	}

	.roundButton {
		border: 1px solid var(--theme-accent-bg1);
		border-radius: 50vh;
		padding: 10px 25px;

		background-color: var(--theme-accent-bg5);
		color: var(--theme-fg1);

		display: flex;
		align-items: center;

		transition: 0.2s ease-out;
	}

	.roundButton:hover {
		background-color: var(--theme-accent-bg1);
		filter: drop-shadow(0px 0px 16px rgb(from var(--theme-accent-bg1) r g b / 80%));
	}

	@media (max-width: 768px) {
		.aboutRow {
			flex-direction: column;
		}

		.aboutImage {
			min-width: 100%;
			max-width: 100%;
			min-height: 350px;
			max-height: 350px;
		}

		.header {
			max-height: none;
		}

		.header-row {
			flex-wrap: wrap;
		}

		.header-menu {
			clear: both;
			max-height: 0;
			flex-direction: column;
			flex-basis: 100%;
			padding-top: 5px;
			margin-bottom: 10px;
			margin-left: -18px;
		}

		.header-menu-icon {
			display: flex;
			align-self: start;
			flex-grow: 2;
			margin-top: 22px;
		}

		.header-logo {
			margin-top: 12px;
		}

		.priceCards {
			grid-template-columns: 1fr;
		}

		.reviewImages {
			grid-template-columns: 1fr 1fr;
		}

		.reviewImages > * {
			width: 100%;
		}
	}
</style>
