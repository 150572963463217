
<script>
	export default {
		name: "PriceCard",
	}
</script>

<script setup>
	defineProps([
		'title',
		'description',
		'price'
	])
</script>

<template>
	<div class="card">
		<p
			style="
				color: var(--theme-fg1);
				font-size: 17px;
				font-weight: bold;
			"
		>{{ title }}</p>

		<p
			style="
				color: var(--theme-fg5);
				flex-grow: 1;
			"
		>{{ description }}</p>

		<div
			style="
				display: flex;
				flex-direction: row;
				align-items: center;
				column-gap: 5px;
			"
		>
			<p
				style="
					color: var(--theme-accent-bg1);
					font-size: 22px;
					font-weight: bold;
				"
			>{{ price }}</p>

			<p
				style="
					color: var(--theme-accent-bg1);
					font-size: 14px;
					margin-top: 5px;
				"
			>р.</p>
		</div>
	</div>
</template>

<style scoped>
	.card {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 10px;

		border-radius: 10px;
		padding: 15px 20px;
		border: 1px solid var(--theme-bg12);

		background: linear-gradient(220deg, var(--theme-bg7) 0%, var(--theme-bg10) 100%);

		filter: drop-shadow(0px 5px 8px #00000066);

		transition: filter 0.2s ease-in-out;
	}

	.card:hover {
		filter: drop-shadow(0px 5px 12px #00000088);
	}
</style>